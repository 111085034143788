import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@deposits/ui-kit-react';
import NavLink from './NavLink';
import Sidebar from './Sidebar';

import { navItems } from './navItems';
import { rilLogo, hamburgerIcon } from '../../../assets/svgs';

const Header = () => {
  const [sidebar, setSidebar] = useState(false);

  return (
    <header className="bg-white fixed top-0 left-0 w-full z-30 lg:flex items-center justify-center whitespace-nowrap px-4 !py-2">
      <nav className=" flex justify-between items-center py-3  container mx-auto">
        <Link to="/overview">
          <div className="cursor-pointer">
            <img alt="RIL logo" src={rilLogo} />
          </div>
        </Link>

        <ul className="hidden lg:flex  items-center gap-x-10">
          {navItems?.map((item) => (
            <NavLink key={item?.id} item={item} />
          ))}
        </ul>

        <a
          href="https://renaissancelabs.org/contact-us"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className="!hidden lg:!block !bg-[#1A1A1A] !text-white  border !border-black  capitalize"
            colorScheme="primary"
            size="large"
          >
            contact us
          </Button>
        </a>

        <div
          role="button"
          className="lg:hidden"
          onClick={() => setSidebar(true)}
        >
          <img alt="hamburger" src={hamburgerIcon} height={25} width={25} />
        </div>
      </nav>

      <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
    </header>
  );
};

export default Header;
