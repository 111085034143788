import React from 'react';

const ContributorCard = ({ item }) => {
  return (
    <div className=" h-28 w-64 lg:w-72 flex flex-col justify-center gap-y-2 !px-6 rounded-lg bg-neutral_09 border border-renaissance_gray_1  text-sm lg:text-base text-white ">
      <h3 className="text-sm lg:text-base font-semibold">{item.name}</h3>

      <span className="text-xs w-fit block !px-4 py-2 rounded-2xl bg-card_black text-renaissance_gray_4">
        {item.title}
      </span>
    </div>
  );
};

export default ContributorCard;
