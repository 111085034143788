import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ ctaText, ctaIcon, item }) => {
  return (
    <div className="h-80 bg-card_white border border-renaissance_gray_4 rounded-lg">
      <div className="h-4/5 flex flex-col justify-center gap-y-4 !px-5 py-8">
        <div>
          {item?.isProduct ? (
            <div className="h-8 w-8 rounded-full bg-renaissance_black"></div>
          ) : (
            <div className="flex items-center justify-between">
              <span className="text-xs  rounded-2xl bg-renaissance_gray_1 text-white block w-fit py-1 !px-3">
                {item?.tag}
              </span>

              <span className="text-[10px] text-renaissance_gray_1">
                {item?.date}
              </span>
            </div>
          )}
        </div>

        <div>
          {item?.isProduct ? (
            <h3 className="text-lg font-semibold text-renaissance_black">
              {item?.title}
            </h3>
          ) : (
            <h3 className="text-lg font-semibold text-renaissance_black">
              {item?.title?.length > 40
                ? `${item?.title?.substring(0, 40)}...`
                : item?.title}
            </h3>
          )}

          <p className="mt-2 text-sm text-renaissance_black">
            {item?.description}
          </p>

          {item?.isProduct && (
            <div className="flex items-center gap-2 mt-4 w-fit py-2 !px-3  border-[0.5px] border-renaissance_gray_1 rounded-2xl">
              {item?.tag === 'Drop' ? (
                <img
                  height="20"
                  width="32"
                  src={item?.icon}
                  alt={`${item?.tag} icon`}
                />
              ) : (
                <img src={item?.icon} alt={`${item?.tag} icon`} />
              )}

              <span className="text-sm text-renaissance_gray_1  ">
                {item?.tag}
              </span>
            </div>
          )}
        </div>
      </div>

      <Link to={item?.link}>
        <div className="cursor-pointer flex items-center justify-between border-t border-renaissance_gray_3 py-5 !px-6">
          <span className="text-sm text-renaissance_black">{ctaText}</span>

          <button className="flex items-center justify-center">
            <img src={ctaIcon} alt={`${ctaText} icon`} />
          </button>
        </div>
      </Link>
    </div>
  );
};

export default Card;
