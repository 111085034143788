import React from 'react';
import { Link } from 'react-router-dom';
import { rightArrowGrayCircle, rilLogoWhite } from '../../../assets/svgs';

import { footItem1, footItem2 } from '../../../utils/dummyData';

const Footer = () => {
  return (
    <footer className="bg-renaissance_black text-white pt-16 pb-12 xl:pr-8">
      <div className=" flex flex-col justify-between gap-y-14 px-[16px] xl:px-0 container mx-auto ">
        <section className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-12  gap-x-8 xl:gap-x-0 gap-y-8">
          <div className="cursor-pointer xl:col-span-3 ">
            <img alt="RIL logo" src={rilLogoWhite} />
          </div>

          <div className="xl:col-span-4 xl:justify-self-center  ">
            <form>
              <label className="text-sm text-white">Newsletter signup</label>

              <div className="mt-4 flex gap-x-4 items-center justify-between p-3 rounded bg-form_gray_light border border-form_gray">
                <input
                  className="bg-transparent !border-0 outline-0 flex-1"
                  type="text"
                  placeholder="Email Address"
                />
                <img src={rightArrowGrayCircle} alt="submit email address" />
              </div>
            </form>
          </div>

          <div className=" md:col-span-2 xl:col-span-5 xl:justify-self-end  flex justify-between gap-4 xl:gap-x-24">
            <ul aria-label="footer-item-1" className="flex flex-col gap-y-2">
              {footItem1?.map((item) => (
                <Link to={item?.link} key={item.id}>
                  <li className="text-white/90">{item.text}</li>
                </Link>
              ))}
            </ul>

            <ul aria-label="footer-item-2" className="flex flex-col gap-y-2">
              {footItem2?.map((item) => (
                <Link to={item?.link} key={item.id}>
                  <li className={`text-white/90 flex items-center`}>
                    <span className="text-sm">{item.text}</span>

                    {item?.icon && (
                      <img className="ml-2" src={item?.icon} alt="go to icon" />
                    )}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </section>

        <section className="text-white text-sm text-center lg:text-left">
          <span>Renaissance Innovation Labs. All Rights Reserved 2023</span>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
