import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './routes';

import Overview from '../screens/Overview';
import ViewProjects from '../screens/ViewProjects';
import NotFound from '../screens/404.js';

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route index path={`/`} element={<Overview />} />
      <Route path={routes.overview} element={<Overview />} />
      <Route path={routes.product} element={<ViewProjects />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
