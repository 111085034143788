import React, { useState } from 'react';
import { products } from '../../utils/dummyData';
import Card from './Card';
import { rightArrow, rightArrowBlue } from '../../assets/svgs';

const OurProducts = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <section
      id="playground"
      className="px-4 lg:px-6  xl:px-0 container mx-auto py-16"
    >
      <h2 className="font-bold text-renaissance_black text-2xl lg:text-5xl capitalize">
        our projects
      </h2>

      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-8">
        {showMore
          ? products?.map((item) => (
              <Card
                key={item.id}
                item={item}
                ctaText="Enter"
                ctaIcon={rightArrow}
              />
            ))
          : products
              ?.slice(0, 7)
              ?.map((item) => (
                <Card
                  key={item.id}
                  item={item}
                  ctaText="Enter"
                  ctaIcon={rightArrow}
                />
              ))}

        {!showMore && (
          <div className="flex flex-col justify-center">
            <div className="h-4/5 flex flex-col justify-center gap-y-4 !px-5 py-8">
              <div>
                <h3 className="text-lg font-semibold text-renaissance_black">
                  Check the full playground
                </h3>

                <p className="mt-4 text-sm text-renaissance_black">
                  Get excited with different projects built by our teams.
                </p>
              </div>
            </div>
            <div className="py-5 !px-6">
              <button
                onClick={() => setShowMore(true)}
                className="flex items-center justify-between w-full"
              >
                <span className="text-sm text-renaissance_black">
                  Full Playground
                </span>

                <img src={rightArrowBlue} alt="enter playground" />
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OurProducts;
