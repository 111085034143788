import { Link, useLocation } from 'react-router-dom';

const NavLink = ({ item, close }) => {
  const { pathname } = useLocation();

  return (
    <li
      onClick={close}
      className="list-none relative  hover:text-newBlack active:black transition"
    >
      <Link
        key={item.title}
        to={item.link}
        className={`font-normal text-sm hover:text-newBlack ${
          pathname.includes(item?.link)
            ? 'text-renaissance_black transition'
            : 'text-renaissance_black'
        } `}
      >
        <span className="capitalize block">{item.title}</span>
      </Link>
    </li>
  );
};

export default NavLink;
