import React, { useEffect, useRef } from 'react';
import Layout from '../components/layouts/Layout';
import { useLocation } from 'react-router-dom';
import { playSVGGIF } from '../assets/images';
import {
  OurProducts,
  Articles,
  Explore,
  Contributor,
} from '../components/sections';

const Overview = () => {
  const location = useLocation();
  const queryParams = location.search.substring(1); // remove the leading `?`

  // Get the value of the `rilq` query parameter
  const rilq =
    queryParams &&
    queryParams
      .split('&')
      .find((param) => param?.startsWith('rilq='))
      .split('=')[1];

  const playgroundRef = useRef();
  const contributorRef = useRef();

  useEffect(() => {
    if (rilq === 'playground') {
      playgroundRef?.current?.scrollIntoView();
    } else if (rilq === 'contributors') {
      contributorRef?.current?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [rilq]);

  return (
    <Layout>
      <>
        <div className="lg:mt-0 !px-4 lg:!px-20 flex flex-col lg:flex-row gap-y-4 lg:justify-between lg:items-center">
          <h1 className="mt-10 lg:mt-0 uppercase lg:w-2/5 font-bold text-renaissance_black text-3xl lg:text-5xl ">
            Renaissance innovation labs{' '}
          </h1>

          <div className="relative h-64 lg:h-96 w-fit mx-auto lg:mx-0">
            <img
              className="object-cover h-full"
              src={playSVGGIF}
              alt="playground svg animation display"
            />
          </div>
        </div>

        <section className="mb-10">
          <h2 className="animate-typing relative after:content-[''] after:block after:h-[150px] after:w-1.5 after:absolute after:-right-1 after:top-1/4 after:bg-[#ccc] whitespace-nowrap overflow-hidden  text-[14vw] uppercase font-bold text-neutral_03">
            playground
          </h2>
        </section>

        <Explore />

        <div ref={playgroundRef}>
          <OurProducts />
        </div>

        <div ref={contributorRef}>
          <Contributor />
        </div>

        <Articles />
      </>
    </Layout>
  );
};

export default Overview;
