import { createContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { toast } from "react-toastify";


const defaultTheme = {

};

export const AppContextInstance = createContext({});

const AppContext = ({ children }) => {
  const [theme,] = useState(defaultTheme);

  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (err) => {
          toast.error(err?.response?.data?.message || err?.message);
        }
      }
    }
  })
  const initialState = {}


  return (
    <AppContextInstance.Provider value={initialState}>
      <QueryClientProvider client={queryClient}  >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </QueryClientProvider>
    </AppContextInstance.Provider>
  );
};

export default AppContext;
