import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const Layout = ({ children }) => {
  return (
    <div>
      <Header />

      <div className="overflow-y-auto pt-[92px] snap-y	snap-mandatory	">
        <main className="snap-start ">{children}</main>

        <div className="bottom_page">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
