import React from 'react';
import AppRouter from './router/AppRouter';
import AppContext from './store/appContext';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className=" font-openSans accent-purple_2 selection:bg-purple_2/20">
      <AppContext>
        <AppRouter />
        <ToastContainer />
      </AppContext>
    </div>
  );
}

export default App;
