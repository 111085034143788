import { routes } from '../../../router/routes';
export const navItems = [
  {
    id: 1,
    title: 'Our Playground',
    link: `/${routes.overview}?rilq=playground`,
  },

  {
    id: 2,
    title: 'Contributors',
    link: `/${routes.overview}?rilq=contributors`,
  },

  {
    id: 3,
    title: 'Our Community',
    link: 'https://renaissancelabs.org/community',
  },
];
