import React from 'react';
import { navItems } from './navItems';
import { closeBlack } from '../../../assets/svgs';

import { Button } from '@deposits/ui-kit-react';

import NavLink from './NavLink';

const Sidebar = ({ sidebar, setSidebar }) => {
  const closeSideBar = () => {
    if (sidebar) {
      setSidebar(false);
    }
  };

  return (
    <>
      <div
        className={`fixed top-0  bottom-0 h-full  z-50  w-80 sm:w-96  text-white text-center  lg:hidden transition-all ease-in-out duration-300 ${
          sidebar ? 'right-0' : '-right-96'
        } `}
      >
        <div
          className="bg-white p-5 cursor-pointer absolute right-4"
          role="button"
          onClick={() => setSidebar(false)}
        >
          <img alt="close icon" src={closeBlack} height={20} width={20} />
        </div>

        <ul className="h-full flex flex-col justify-center items-center  bg-white gap-y-8">
          {navItems?.map((item) => (
            <NavLink key={item?.id} item={item} close={closeSideBar} />
          ))}
          <a
            href="https://renaissancelabs.org/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="lg:!hidden !bg-[#1A1A1A] !text-white  border !border-black  capitalize"
              colorScheme="primary"
              size="large"
            >
              contact us
            </Button>
          </a>
        </ul>
      </div>
      {/* overlay */}
      {sidebar && (
        <div
          onClick={closeSideBar}
          className="h-full w-full z-20 bg-renaissance_black/20 fixed top-0 right-0 bottom-0"
        ></div>
      )}
    </>
  );
};

export default Sidebar;
