import React from 'react';

import { rightArrow } from '../../assets/svgs';
import { playGIF } from '../../assets/images';

const Explore = () => {
  return (
    <section className="  py-10 !px-4 container ml-auto grid grid-cols-1 lg:grid-cols-6 gap-y-4 gap-x-8">
      <div className="lg:col-span-2 justify-center ">
        <img src={playGIF} alt="two play icons" />
      </div>

      <article className="lg:col-span-4">
        <h2 className="mb-8 text-2xl lg:text-4xl text-renaissance_black font-bold">
          Explore the{' '}
          <span className="font-semibold text-main_blue_100">
            Future of Innovation
          </span>{' '}
        </h2>

        <div className="text-base lg:text-lg text-neutral_09 lg:w-4/5">
          <p>
            At Renaissance Innovation Labs, innovation is at the heart of
            everything we do. Our product playground is your exclusive pass to
            witness the culmination of our creative efforts, a showcase of the
            products that have redefined industries and elevated experiences.
          </p>

          <p className="mt-4">
            From groundbreaking software solutions to cutting-edge hardware, our
            product playground is a testament to our commitment to excellence.
            Dive into a world of digital wonders, where ideas come to life, and
            possibilities are endless.
          </p>
        </div>

        <div className="lg:flex gap-x-10 mt-5 lg:mt-10">
          <a
            href="https://renaissancelabs.org/services"
            target="_blank"
            rel="noreferrer"
          >
            <button className="mb-8 lg:mb-0 p-5 flex items-center gap-x-4 border-l border-l-main_blue_100  hover:border-b hover:border-t hover:border-r ">
              <span className="capitalize text-base text-renaissance_black">
                Build with us
              </span>
              <img src={rightArrow} alt="right arrow" />
            </button>
          </a>

          <div>
            <a
              href="https://github.com/sponsors/Renaissance-Innovation-Labs?o=esb"
              target="_blank"
              rel="noreferrer"
            >
              <button className=" lg:mt-0 p-5 flex items-center gap-x-4 border-l border-l-main_blue_100  hover:border-b hover:border-t hover:border-r ">
                <span className="capitalize text-base text-renaissance_black">
                  Buy us a coffee
                </span>
                <img src={rightArrow} alt="right arrow" />
              </button>
            </a>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Explore;
