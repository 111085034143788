import React from 'react';
import Marquee from 'react-fast-marquee';
import { contributors } from '../../utils/dummyData';
import ContributorCard from './ContributorCard';

const Contributor = () => {
  var half_length = Math.ceil(contributors.length / 2);

  var topSide = contributors.slice(0, half_length);

  var bottomSide = contributors.slice(half_length, contributors.length);

  return (
    <section className="bg-renaissance_black py-20">
      <h2 className="!px-4 lg:!pl-20 font-bold text-white text-2xl lg:text-5xl capitalize">
        contributors
      </h2>

      <div className="mt-10 mb-8">
        <Marquee speed={150}>
          {topSide?.map((item) => (
            <div key={item.id} className="mr-8">
              <ContributorCard item={item} />
            </div>
          ))}
        </Marquee>
      </div>

      <div>
        <Marquee speed={250}>
          {bottomSide?.map((item) => (
            <div key={item.id} className="mr-8">
              <ContributorCard item={item} />
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};

export default Contributor;
