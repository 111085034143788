import React from 'react';

import { articles } from '../../utils/dummyData';
import Card from './Card';
import { goLink, hashnode } from '../../assets/svgs';

const Articles = () => {
  return (
    <section className="px-[16px] lg:px-[24px]  xl:px-0 container mx-auto py-10">
      <h2 className="mb-10 font-bold text-renaissance_black text-2xl lg:text-5xl capitalize">
        Articles
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-8">
        {articles?.map((item) => (
          <Card
            key={item.id}
            item={item}
            ctaText="Read More"
            ctaIcon={goLink}
          />
        ))}
      </div>

      <div className="py-5 !px-4 mt-4">
        <a
          target="_blank"
          href="https://blog.renaissancelabs.org/"
          rel="noreferrer"
        >
          <button className="flex items-center gap-x-4">
            <img
              src={hashnode}
              alt="go renaissance innovation labs hashnode blog"
            />

            <span className="text-sm text-renaissance_black">
              Follow us on hashnode
            </span>
          </button>
        </a>
      </div>
    </section>
  );
};

export default Articles;
